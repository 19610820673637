import { css } from '@emotion/react'
import { spacing, Text } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'

export type BackofficeSectionTitleProps = {
  children: ReactNode
}

export function BackofficeSectionTitle({ children }: BackofficeSectionTitleProps): JSX.Element {
  return (
    <Text
      variant="h4"
      css={css`
        margin-top: ${spacing[70]};
      `}
    >
      {children}
    </Text>
  )
}
